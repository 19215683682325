import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { HeaderType } from "@marketplace/enums/header-types.enums";
import { AuthService } from "@shared/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "@shared/_services/authentication.service";
import { AbstractMarkeplaceHeaderComponent } from "../AbstractMarkeplaceHeader.component";
import { Utils, getPathFromUrl } from "@shared/constant/shared.utils";
import { ConstantService } from "@shared/constant/shared.constant";
import { UserPublicService } from "@shared/services";
import { PushNotificationUtils } from "@marketplace/utils/PushNotification.utils";
import { CustomCalendlyWidgetComponent } from "@shared/custom-calendly-widget/custom-calendly-widget.component";
import { UserAgentUtils } from "@shared/utils/UserAgent.utils";

@Component({
  selector: "marketplace-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent
  extends AbstractMarkeplaceHeaderComponent
  implements OnInit
{
  @ViewChild("customCalendlyWidget") calendly: CustomCalendlyWidgetComponent;
  queryParams = {};

  isLoggedIn = false;

  isCollapse = true;

  isSubMenu = false;

  isSeller = false;

  whyboxxport = false;

  showBanner = false;

  ourCompany = false;

  closeNotificationWidget = new EventEmitter<any>();
  url: string;
  helpUrlMap = new Map([
    ["/", "start"],
    ["/aboutUs", "aboutus"],
    ["/ourTeam", "aboutus"],
    ["/careers", "start"],
    ["/profile", "youraccount"],
    ["/profile/details", "youraccount"],
    ["/profile/org/listing", "youraccount"],
    ["/profile/org/add/companyDetails", "youraccount"],
    ["/profile/seller/profileRoles", "youraccount"],
    ["/buyer/dashboard", "documents"],
    ["/buyer/cart", "payment"],
    ["/buyer/orders/listing/live", "order"],
    ["/buyer/orders/listing/closed", "order"],
    ["/buyer/orders/listing/cancelled", "order"],
    ["/buyer/product/listing", "start"],
    ["/buyer/negotiate/listing/accepted", "start"],
    ["/buyer/negotiate/listing/newOffer", "start"],
    ["/buyer/negotiate/listing/rejected", "start"],
    ["/buyer/negotiate/listing/requested", "start"],
    ["/seller/dashboard", "seller"],
    ["/seller/product/depot", "depot"],
    ["/seller/product/depot/add/details", "depot"],
    ["/seller/product/depot/add/complete", "depot"],
    ["/seller/product/listing", "listing"],
    ["/seller/product/listing/active", "listing"],
    ["/seller/product/listing/inactive", "listing"],
    ["/seller/product/listing/closed", "listing"],
    ["/seller/product/listing/add/type", "listing"],
    ["/seller/product/listing/add/type/bulk/upload", "listing"],
    ["/seller/product/listing/add/type/individual/upload", "listing"],
    ["/seller/product/listing/add/type/individual/containerDetails", "listing"],
    ["/seller/orders/listing/live", "seller"],
    ["/seller/orders/listing/closed", "seller"],
    ["/seller/orders/listing/cancelled", "seller"],
    ["/seller/negotiate", "start"],
    ["/seller/negotiate/seeAllListing", "start"],
  ]);

  profiletabs = [
    {
      image: `${this.assetPath}/images/new-svg/selldashicon.svg`,
      tabName: "Seller Dashboard",
      link: "/seller/dashboard",
    },
    {
      image: `${this.assetPath}/images/new-svg/buydashicon.svg`,
      tabName: "Buyer Dashboard",
      link: "/buyer/dashboard",
    },
    {
      image: `${this.assetPath}/images/new-svg/accounticon.svg`,
      tabName: "Account Details",
      link: "/profile",
    },
  ];

  producttabs = [
    {
      image: `${this.assetPath}/images/new-svg/sellingicon.svg`,
      heading: "Selling",
      desc: "Why sell with BOXXPORT",
      link: "/sell-shipping-containers",
    },
    {
      image: `${this.assetPath}/images/new-svg/radardashicon.svg`,
      heading: "Radar",
      desc: "Auto-alerts for container demand",
      link: "/radar-shipping-containers",
    },
    {
      image: `${this.assetPath}/images/new-svg/buyingicon.svg`,
      heading: "Buying",
      desc: "Why buy with BOXXPORT",
      link: "/buy-shipping-containers",
    },
    {
      image: `${this.assetPath}/images/new-svg/buybackdashicon.svg`,
      heading: "Buyback",
      desc: "Buy containers and sell right back to us",
      link: "/buyback-shipping-containers",
    },
    {
      image: `${this.assetPath}/images/new-svg/negotiateicon.svg`,
      heading: "Negotiating",
      desc: "Buy and sell with flexibility",
      link: "/buy-sell-negotiations",
    },
    {
      image: `${this.assetPath}/images/new-svg/speedreleaseicon.svg`,
      heading: "SpeedRelease",
      desc: "Fast payment recognition",
      link: "/speedrelease-shipping-containers",
    },
    {
      image: `${this.assetPath}/images/new-svg/buynowpaylatericon.svg`,
      heading: "Buy Now Pay Later",
      desc: "Buy now and Pay in 15 or 30 days",
      link: "/buy-now-pay-later",
    },
    {
      image: `${this.assetPath}/images/new-svg/header-icon.svg`,
      heading: "e-Wallet",
      desc: "Trade with our digital wallet",
      link: "/eWallet",
    },
  ];

  servicetabs = [
    // {
    //   image: `${this.assetPath}/images/order-management/ordermgmticon.svg`,
    //   heading: "Minimum price report",
    //   desc: "",
    //   link: "/minimum-price-report",
    // },
    {
      image: `${this.assetPath}/images/order-management/ordermgmticon.svg`,
      heading: "Order Management",
      desc: "Transparent view of all your orders",
      link: "/order-management",
    },
    {
      image: `${this.assetPath}/images/new-svg/dashboardicon.svg`,
      heading: "Dashboard",
      desc: "KPI's and many information",
      link: "/dashboard",
    },
    {
      image: `${this.assetPath}/images/account-management/accountmgmticon.svg`,
      heading: "Account Management",
      desc: "Organization,Users and Payments",
      link: "/account-management",
    },
    {
      image: `${this.assetPath}/images/new-svg/personal-support.svg`,
      heading: "Customer Support",
      desc: "We are here to help you",
      link: "/personal-support",
    },
    {
      image: `${this.assetPath}/images/new-svg/gdn_icon.svg`,
      heading: "Global Depot Network",
      desc: "Clear view of the available depots",
      link: "/global-depot-network",
    },
    {
      image: `${this.assetPath}/images/new-svg/apiicon.svg`,
      heading: "API Connection",
      desc: "Want more velocity to your operations?",
      link: "/static-api-page",
    },
    {
      image: `${this.assetPath}/images/new-svg/innovations-header.svg`,
      heading: "Innovations",
      desc: "Pioneering Innovation in the Industry",
      link: "/technologies",
    },

    // {
    //   image: `${this.assetPath}/images/new-svg/apiicon.svg`,
    //   heading: "AOB Landing Page",
    //   desc: "FOR DEVELOPMENT",
    //   link: "/container-kaufen",
    // },
  ];

  companytabs = [
    {
      image: `${this.assetPath}/images/new-svg/aboutusicon.svg`,
      heading: "About Us",
      desc: "Get to know BOXXPORT",
      link: "/aboutUs",
    },
    {
      image: `${this.assetPath}/images/new-svg/teamicon.svg`,
      heading: "Our Team",
      desc: "Ready and ready to help",
      link: "/ourTeam",
    },
    {
      image: `${this.assetPath}/images/new-svg/careericon.svg`,
      heading: "Careers",
      desc: "Have new ideas? Join our team",
      link: "/careers",
    },
    {
      image: `${this.assetPath}/images/new-svg/meetusicon.svg`,
      heading: "Meet-Us",
      desc: "Have new ideas? Join our team",
      link: "/meet-us",
    },
    {
      image: `${this.assetPath}/images/new-svg/blogicon.svg`,
      heading: "Blog",
      desc: "Everything about Containers",
      link: "/blogs",
      isExternal: true,
    },
    {
      image: `${this.assetPath}/images/new-svg/academyicon.svg`,
      heading: "Academy",
      desc: "Tutorials for Boxxport",
      link: "/academy",
    },
  ];

  constructor(
    router: Router,
    authService: AuthService,
    translate: TranslateService,
    authenticationService: AuthenticationService,
    private userPublicService: UserPublicService,
    pushNotificationUtils: PushNotificationUtils
  ) {
    super(
      router,
      authService,
      translate,
      authenticationService,
      pushNotificationUtils
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.userPublicService.enableHeaderBanner().subscribe((data) => {
      if (data && data.result) {
        this.showBanner = true;
      }
    });
  }

  onInit() {
    super.onInit();
    this.isSeller =
      this.flow === HeaderType.HEADER_SELLER ||
      this.flow === HeaderType.HEADER_SELLER_PENDING;
  }

  protected handlerRouteChangeEvent(res) {
    this.url = ConstantService.get("HELP_URL");
    if (this.router.url) {
      this.queryParams["page"] = Math.abs(
        Utils.hashCode(getPathFromUrl(this.router.url))
      );
      if (this.helpUrlMap.get(this.router.url)) {
        this.url =
          this.url + "/search?query=" + this.helpUrlMap.get(this.router.url);
      }
    }
  }
  goToHelp() {
    window.location.href = this.url;
  }

  protected handleLoginEvent(isLogin: boolean) {
    super.handleLoginEvent(isLogin);
    this.isSubMenu = false;
    this.isLoggedIn = isLogin;
  }
  onClickedOutside($event, key) {
    this[key] = false;
  }
  closeAllDropdowns(closeNotification = true) {
    if (UserAgentUtils.isMobile()) {
      return;
    }
    this.isCollapse = true;
    this.whyboxxport = false;
    this.ourCompany = false;
    this.isSubMenu = false;
    if (closeNotification) {
      this.closeNotificationWidget.emit();
    }
  }
  mouseAction(action: string) {
    if (UserAgentUtils.isMobile()) {
      return;
    }
    switch (action) {
      case "productsServices": {
        this.whyboxxport = !this.whyboxxport;
        break;
      }
      case "company": {
        this.ourCompany = !this.ourCompany;
        break;
      }
      case "account": {
        this.isSubMenu = !this.isSubMenu;
        this.isCollapse = true;
        break;
      }
      default: {
        break;
      }
    }
  }

  // closeBanner() {
  //   this.showBanner = true;
  // }
}
