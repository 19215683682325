import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { NoDblClickDirectiveMat } from "@shared/directive/no-dbl-click-mat.directive";
import { NoDblClickDirective } from "@shared/directive/no-dbl-click.directive";
import { KeysPipe } from "@shared/pipe/keys.pipe";
import { Mask } from "@shared/pipe/mask.pipe";
import { ReplacePipe } from "@shared/pipe/replace.pipe";
import { SplitCamelcasePipe } from "@shared/pipe/split-camelcase.pipe";
import { BlockUIModule } from "ng-block-ui";
import { ModalModule } from "ngx-bootstrap/modal";
import { DragScrollModule } from "ngx-drag-scroll";
import { BlockUiComponent } from "./block-ui/block-ui.component";
import { UploadFormComponent } from "./component/upload-form/upload-form.component";
import { ClickStopPropagationDirective } from "./directive/ClickStopPropagation.directive";
import { GooglePlacesDirective } from "./directive/google-places.directive";
import { NumbersOnlyDirective } from "./directive/numbers-only.directive";
import { ControlMessagesComponent } from "./error/control-messages.component";
import { NgClockComponent } from "./ng-clock/ng-clock.component";
import { OwnCaptchaComponent } from "./own-captcha/own-captcha.component";
import { CallbackSortPipe } from "./pipe/callback-sort.pipe";
import { CallbackPipe } from "./pipe/callback.pipe";
import { FormatArrayPipe } from "./pipe/FormatArray.pipe";
import { PaginationPipe } from "./pipe/pagination.pipe";
import { SafePipe } from "./pipe/safe.pipe";
import { ValuesPipe } from "./pipe/values.pipe";
import { RecaptchaCoreModule } from "./recaptcha/recaptcha-core.module";
import { SharedServiceInterceptorModule } from "./_services/shared-service-interceptor.module";
import { CustomCalendlyWidgetComponent } from "./custom-calendly-widget/custom-calendly-widget.component";
import { OrderedKeyValuesPipe } from "./pipe/ordered-key-values.pipe";
import { CamelcaseToTitlecasePipe } from "./pipe/camelcase-titlecase.pipe";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { IsOnViewDirective } from "./directive/is-on-view.directive";

import { WalletTransactionTypePipe } from "./pipe/wallet-transaction-type.pipe";
import { WalletTransactionStatusBadgeDirective } from "./directive/wallet-transaction-status-badge.directive";
import { ToastrComponentComponent } from './toastr-component/toastr-component.component';

export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use("en");
}
@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    SharedServiceInterceptorModule,
    ModalModule.forRoot(),
    DragScrollModule,
    FormsModule,
    RouterModule,
    FontAwesomeModule,
    BlockUIModule.forRoot({
      template: BlockUiComponent,
      message: "Loading...",
      delayStart: 500,
    }),
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    RecaptchaCoreModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [],
  declarations: [
    KeysPipe,
    ValuesPipe,
    FormatArrayPipe,
    CallbackPipe,
    CallbackSortPipe,
    PaginationPipe,
    NumbersOnlyDirective,
    SplitCamelcasePipe,
    Mask,
    ReplacePipe,
    SafePipe,
    NoDblClickDirective,
    NoDblClickDirectiveMat,
    ClickStopPropagationDirective,
    ControlMessagesComponent,
    UploadFormComponent,
    GooglePlacesDirective,
    BlockUiComponent,
    NgClockComponent,
    OwnCaptchaComponent,
    CustomCalendlyWidgetComponent,
    OrderedKeyValuesPipe,
    CamelcaseToTitlecasePipe,
    IsOnViewDirective,

    WalletTransactionTypePipe,
    WalletTransactionStatusBadgeDirective,
    ToastrComponentComponent,
  ],
  exports: [
    CommonModule,
    BlockUIModule,
    SharedServiceInterceptorModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule,
    OwnCaptchaComponent,
    KeysPipe,
    CallbackSortPipe,
    ValuesPipe,
    FormatArrayPipe,
    CallbackPipe,
    PaginationPipe,
    NumbersOnlyDirective,
    SplitCamelcasePipe,
    Mask,
    ReplacePipe,
    SafePipe,
    ControlMessagesComponent,
    NoDblClickDirective,
    RecaptchaCoreModule,
    ReactiveFormsModule,
    NoDblClickDirectiveMat,
    ClickStopPropagationDirective,
    UploadFormComponent,
    NgClockComponent,
    NgSelectModule,
    GooglePlacesDirective,
    CustomCalendlyWidgetComponent,
    OrderedKeyValuesPipe,
    CamelcaseToTitlecasePipe,
    LottieModule,
    IsOnViewDirective,
    WalletTransactionTypePipe,
    WalletTransactionStatusBadgeDirective,
  ],
})
export class SharedModule {}

export function playerFactory() {
  // return import("lottie-web");
  return player;
}
