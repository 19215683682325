import { AddContainerStep1DTO } from "./AddContainerStep1DTO";

export class ContainerPriceHistoryDTO extends AddContainerStep1DTO {
  containerId: string;
  originalPrice: number;

  updatedPrice: number;
  startDate: Date;
  endDate: Date;
  priceHistory: string;

  negotiationAmount: number;

  negotiationAmountUnlimited: Boolean;
  isApplyDiscount: Boolean;

  onRequest: Boolean;
  updatedQuantity: number;
}
