import { EventEmitter, Injectable, Output } from "@angular/core";
import { CompareDTO } from "@marketplace/dto/CompareDTO";
import { ProductListingDTO } from "@marketplace/dto/ProductListingDTO";
import {
  ConstantService,
  STORAGE_PREFIX,
} from "@shared/constant/shared.constant";
import { AbstractStorageService } from "@shared/_services/abstract-storage.service";
import { remove } from "lodash";

@Injectable({
  providedIn: "root",
})
export class MarkerplaceProductCompareService extends AbstractStorageService<
  CompareDTO[]
> {
  private storagePrefix;

  public compareProducts: ProductListingDTO[] = [];

  public searchedProductCount = 0;

  @Output() compareProductsEvent = new EventEmitter<ProductListingDTO[]>();

  constructor() {
    super(localStorage);
    this.storagePrefix = ConstantService.get(STORAGE_PREFIX) + "compare-data";
    let compareArray = this.getItem(this.storagePrefix);
    console.log(compareArray);
    if (!compareArray) {
      compareArray = [];
      this.setItem(this.storagePrefix, compareArray);
    }
    this.updateLatest();
  }

  updateLatest() {}

  public clearAddToCompare() {
    this.compareEvent(undefined, false);
  }
  private compareEvent(product: ProductListingDTO, isAddedForCompare: boolean) {
    if (product) {
      product.isAddedForCompare = isAddedForCompare;
      remove(this.compareProducts, (data: ProductListingDTO) => {
        if (product && product.container) {
          return data.container.identifier === product.container.identifier;
        } else {
          return data.identifier === product.identifier;
        }
        // return data.container.identifier === product.container.identifier;
      });
      if (isAddedForCompare) {
        this.compareProducts.push(product);
      }
    } else {
      const size = this.compareProducts.length;
      for (let i = 0; i < size; i++) {
        const e = this.compareProducts.pop();
        e.isAddedForCompare = isAddedForCompare;
      }
    }
    this.compareProductsEvent.emit(this.compareProducts);
  }

  public isAddedForCompare(identifier: string): boolean {
    return (
      this.compareProducts.findIndex((e) => {
        return e.identifier === identifier;
      }) !== -1
    );
  }

  public addToCompare(product: ProductListingDTO) {
    this.compareEvent(product, true);
  }

  public removeFromCompare(product: ProductListingDTO) {
    this.compareEvent(product, false);
  }
}
