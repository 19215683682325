import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@shared/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "@shared/_services/authentication.service";
import { AbstractMarkeplaceHeaderComponent } from "../AbstractMarkeplaceHeader.component";
import { HeaderType } from "@marketplace/enums/header-types.enums";

@Component({
  selector: "marketplace-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent extends AbstractMarkeplaceHeaderComponent {
  private sellerOption = [
    {
      routerLink: "seller/dashboard",
      class: "icn-home",
      label: "Seller Dashboard",
      color: "yellow",
    },
    {
      routerLink: "seller/product/depot",
      class: "icn-box",
      label: "Depot Management",
      color: "blue",
    },
    {
      routerLink: "seller/product/listing",
      class: "icn-list",
      label: "Container Listing",
      color: "blue-light",
    },
    {
      routerLink: "seller/orders/listing",
      class: "icn-banknote",
      label: "Order Management",
      color: "green",
    },
    {
      routerLink: "seller/negotiate",
      class: "icn-nego",
      label: "Negotiation Management",
      color: "pink-dark",
    },
    {
      routerLink: "seller/radar",
      class: "icn-radar",
      label: "Radar Management",
      color: "purple",
    },
    {
      routerLink: "seller/wallet/home",
      class: "icn-wallet",
      label: "Seller Wallet Management",
      color: "blue-light",
    },
  ];

  private buyerOption = [
    {
      id: "home",
      routerLink: "buyer/dashboard",
      class: "icn-home",
      label: "Buyer Dashboard",
      color: "orange",
    },
    {
      routerLink: "buyer/orders/listing",
      class: "icn-pepe",
      label: "Orders Management",
      color: "yellow",
    },
    {
      routerLink: "buyer/favourite",
      class: "icn-heart",
      label: "Following listing",
      color: "green",
    },
    {
      routerLink: "buyer/buyback",
      class: "icn-buyback",
      label: "Buyback Management",
      color: "pink-dark",
    },
    {
      routerLink: "buyer/negotiate",
      class: "icn-nego",
      label: "Negotiation Management",
      color: "pink-dark",
    },
    {
      routerLink: "buyer/radar",
      class: "icn-radar",
      label: "Radar Management",
      color: "aqua",
    },
    {
      routerLink: "buyer/wallet/home",
      class: "icn-wallet",
      label: "Buyer Wallet Management",
      color: "blue-light",
    },
  ];

  private sellerOptionPending = this.sellerOption
    .map((e) => Object.assign({}, e))
    .map((e, index) => {
      if (index === 0) {
        e.routerLink = "pending/seller";
      } else {
        e["isDisabled"] = true;
      }
      return e;
    });

  sidebars;

  constructor(
    router: Router,
    authService: AuthService,
    translate: TranslateService,
    authenticationService: AuthenticationService
  ) {
    super(router, authService, translate, authenticationService);
  }

  onInit() {
    super.onInit();
    const flowMap = {};
    flowMap[HeaderType.HEADER_SELLER] = this.sellerOption;
    flowMap[HeaderType.HEADER_SELLER_PENDING] = this.sellerOptionPending;
    flowMap[HeaderType.HEADER_BUYER] = this.buyerOption;
    flowMap[HeaderType.HEADER_SELLER_REG_SUSPENDED] = this.sellerOptionPending;
    this.sidebars = flowMap[this.flow];
  }

  toggleDashboard() {
    let url = "/seller/dashboard";
    if (this.isSeller) {
      url = "/buyer/dashboard";
    }
    this.router.navigate([url]);
  }

  public setRoutedComponent(componentRef: any) {
    console.log(componentRef);
  }
}
