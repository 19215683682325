import { DatePipe } from "@angular/common";
import { Component, Injector, OnInit } from "@angular/core";
import { ZendeskService } from "@marketplace/service/zendesk.service";
import { environment } from "@marketplaceEnv/environment";
import { AbstractAppComponent } from "@shared/abstracts/abstract-app.component";
import { AuthService } from "@shared/auth/auth.service";
import { BlockUiDTO } from "@shared/block-ui/blockui.dto";
import { Utils } from "@shared/constant/shared.utils";
import { PingService } from "@shared/services";
import { ExternalSubscriptionService } from "@shared/services/external-subscription.service";
import { ErrorInterceptor } from "@shared/_helpers";
import { ConnectionService } from "@shared/_services/ConnectionService.service";
import { BlockUIService } from "ng-block-ui";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { HeaderType } from "./enums/header-types.enums";
import { ErrorModalComponent } from "./marketplace-core/error-modal/error-modal.component";
import { SubscribeExternalAppComponent } from "./marketplace-core/subscribe-external-app/subscribe-external-app.component";
import { PushNotificationUtils } from "./utils/PushNotification.utils";

@Component({
  selector: "marketplace-root",
  templateUrl: "./marketplace.component.html",
  styleUrls: ["./marketplace.component.scss"],
})
export class MarketplaceAppComponent
  extends AbstractAppComponent
  implements OnInit
{
  blockUIuuid = Utils.uuid();

  headerType = "DEFAULT";

  status = "ONLINE";

  data: object;

  isConnected: boolean = navigator.onLine;

  errorEvent: CustomEvent;

  loadPage: Boolean = false;

  isUserLoggedIn = false;

  constructor(
    protected injector: Injector,
    private modalService: BsModalService,
    private pingService: PingService,
    protected blockUIService: BlockUIService,
    private authService: AuthService,
    private connectionService: ConnectionService,
    private zendeskService: ZendeskService,
    private errorInterceptor: ErrorInterceptor,
    private datePipe: DatePipe,
    private externalSebscriptionService: ExternalSubscriptionService,
    protected pushNotificationUtils: PushNotificationUtils // public viewRootRef: ViewContainerRef // Please DO NOT REMOVE needed by dynamic loading elements  USE IT WHEN USING CUSTOM MAPS WIDGET
  ) {
    super(injector);
  }

  ngOnInit(): void {
    // this.checkForLocale();
    this.subs.push(
      this.connectionService.monitor().subscribe((isConnected) => {
        this.isConnected = isConnected;
        this.handleConnection();
      })
    );
    this.subs.push(
      this.errorInterceptor.callToSubscriptionEvent.subscribe(() => {
        this.checkAndLoadSubscriptionModal();
      })
    );
    this.handleConnection();
    window.addEventListener("errorEvent", this.errorEventHandler);
    this.authService.getLoggedIn.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.setPushNotification();
      }
    });
  }

  private handleConnection() {
    this.isUserLoggedIn = this.authService.isUserLoggedIn();
    if (this.isConnected) {
      this.blockUIService.stop(this.blockUIuuid);
      {
        if (this.isUserLoggedIn)
          setTimeout(() => {
            this.setPushNotification();
          }, 10000);
      }
    } else {
      if (environment.production) {
        const blockUiDTO: BlockUiDTO = new BlockUiDTO();
        blockUiDTO.message = `Looks like there is no Network. Please try to reconnect.
        <br/><br/>Though we keep trying to check your network.
        <br/><br/>But if you feel network is there and this page persists then please refresh this page.
        `;
        blockUiDTO.showLoader = false;
        this.blockUIService.start(this.blockUIuuid, blockUiDTO);
      } else {
        alert("Internet not available");
        this.isConnected = true;
      }
    }
  }

  private errorEventHandler = (errorEvent: CustomEvent) => {
    if (
      !this.errorEvent &&
      this.modalService &&
      errorEvent.detail &&
      errorEvent.detail.status > 499
    ) {
      this.errorEvent = errorEvent;
      if (
        (errorEvent.detail.status > 500 && errorEvent.detail.status < 505) ||
        errorEvent.detail.status === 0
      ) {
        // this.router.navigate(["/serverDown"]);
      } else {
        console.error("Error events:", errorEvent);
        const initialState = {
          callbackFunc: (bsModalRef: BsModalRef) => {
            bsModalRef.hide();
            this.errorEvent = undefined;
          },
          errorEvent,
        };
        this.modalService.show(ErrorModalComponent, {
          initialState,
          keyboard: false,
          ignoreBackdropClick: true,
        });
      }
    }
  };

  routerChangeEvent(data) {
    this.errorEvent = undefined;
    let header;
    this.data = data;
    const isUserLoggedIn = this.authService.isUserLoggedIn();
    if (data) {
      header = data["header-" + isUserLoggedIn] || data["header"];
    }
    this.headerType = isUserLoggedIn
      ? header
        ? header
        : HeaderType.DEFAULT
      : HeaderType.DEFAULT;

    this.zendeskService.initZendesk();
  }

  private checkAndLoadSubscriptionModal() {
    const loadSubscriptionPopup =
      document.getElementsByClassName("calendly-popup").length === 0;
    if (loadSubscriptionPopup) {
      const format = "dd/MM/yyyy";
      const todaysDateAsString = this.datePipe.transform(new Date(), format);
      if (this.authService.isUserLoggedIn()) {
        const loggedInUser = this.authService.getLoggedInUser();
        const loggedUsername = loggedInUser.email;
        this.externalSebscriptionService
          .getContactInformation(loggedUsername)
          .pipe()
          .subscribe(
            (data) => {
              console.log(data);
              if (!data.result && this.checkForExternalCallToBeMade()) {
                this.modalService.show(SubscribeExternalAppComponent, {});
                localStorage.setItem("subscriptionDate", todaysDateAsString);
              } else if (data.result) {
                localStorage.setItem("subscriptionDate", todaysDateAsString);
              }
            },
            (error) => {
              console.log(error);
              if (this.checkForExternalCallToBeMade()) {
                this.modalService.show(SubscribeExternalAppComponent, {});
                localStorage.setItem("subscriptionDate", todaysDateAsString);
              }
            }
          );
      } else {
        if (this.checkForExternalCallToBeMade()) {
          this.modalService.show(SubscribeExternalAppComponent, {});
          localStorage.setItem("subscriptionDate", todaysDateAsString);
        }
      }
    }
  }

  private checkForExternalCallToBeMade(): Boolean {
    const subscriptionDate = localStorage.getItem("subscriptionDate");
    const format = "dd/MM/yyyy";
    const todaysDateAsString = this.datePipe.transform(new Date(), format);
    if (subscriptionDate === todaysDateAsString) {
      console.log("subscription date after event is todays date");
      return false;
    }
    if (subscriptionDate === undefined) {
      console.log("subscription date after event is undefined");
      return true;
    } else if (subscriptionDate !== todaysDateAsString) {
      console.log("subscription date after event is ", subscriptionDate);
      console.log(
        "todaysDateAsString date after event is ",
        todaysDateAsString
      );
      return true;
    }
  }

  setPushNotification() {
    this.pushNotificationUtils.askPermission().then((res: any) => {
      console.log(res, "notification permission");
      if (res == "granted") {
        this.pushNotificationUtils.checkForUpdates().then((res) => {
          console.log(res);
          if (res == "install") {
            // let registration =
            this.pushNotificationUtils.subscribeUserToPush();
          }
        });
      }
    });
  }

  /* private checkForLocale() {
    const locale = sessionStorage.getItem('locale');
    if (Utils.isEmpty(locale)) {
      this.pingService.localeGet()
      .pipe()
      .subscribe(data => {
        console.log(data);
        this.loadPage = true;
      }, error => {
        this.loadPage = true;
      });
    } else {
      this.loadPage = true;
    }
  } */
}
