import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractBaseService } from "@shared/abstracts/abstract-base-service";
import { BaseResponseDTO } from "@shared/dto";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService extends AbstractBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  setNotificationRegistration(registration: any) {
    return this.http.post(
      `${this.apiBaseUrl}pushNotification/saveBrowserDetails`,
      registration
    );
  }

  boxxnewsOnLogin(): Observable<BaseResponseDTO<any>> {
    return this.http.get<BaseResponseDTO<any>>(
      `${this.apiBaseUrl}p/news/getAllNews`
    );
  }
}
