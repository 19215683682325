import { AbstractContainerProductDTO } from "@shared/dto/AbstractContainerProductDTO";
import { ContainerSpecialEquipmentDTO } from "./ContainerSpecialEquipmentDTO";
import { InnerRALColorDTO } from "./InnerRALColorDTO";
import { InventoryLocationDto } from "./InventoryLocationDto";
import { OuterRALColorDTO } from "./OuterRALColorDTO";
import { ContainerTypeDTO } from "./ContainerTypeDTO";
import { ContainerGradeDTO } from "./ContainerGradeDTO";
import { ContainerPriceDto } from "./ContainerPriceDto";

export class AddContainerStep1DTO extends AbstractContainerProductDTO {
  identifier: string;

  containerLinkIdentifier?: string;

  sellerOrgIdentifier: string;

  pcId: number;

  productCode: string;

  productExternalCode: string;

  validFrom: Date;

  validTo: Date;

  cscValidTill: Date;

  yearOfManufacture: number;

  sellAsLot: boolean;

  cscCertificateAvailable: boolean;

  cscPlateAvailable: boolean;

  openEnd: boolean;

  status: string;

  type: ContainerTypeDTO;

  grade: ContainerGradeDTO;

  totalQuantity: number;

  inventoryLocationList: InventoryLocationDto[];

  specialEquipmentList: ContainerSpecialEquipmentDTO[];
  innerRALColor: InnerRALColorDTO;
  outerRALColor: OuterRALColorDTO;
  marketPlaceCommission: number;
  containerPrefix: string;
  priceUpdateRemaining: number;
  priceUpdateCount: number;
  availableFrom: Date;
  price: ContainerPriceDto;

  negotiable: boolean;
  availableTo: Date;
}
