<div class="marginTB10">
  <span class="fw-400 font14 text-blue-light">
    Handle all your bulk buying requests, review your responses, and get
    important KPIs for your negotiation history.
  </span>
  <!-- <span class="bold">Below details</span> will ONLY display data of -->
  <!-- <a
    [routerLink]="['/profile/org/listing']"
    [queryParams]="{ from: router.url }"
    class="bold mr-3"
    >({{ organizationDTO.companyName }}).</a
  > -->
  <!-- <a
    class="btn btn-bluebg btn-xs font12"
    *ngIf="showRedirectButton"
    [routerLink]="['/profile/org/listing']"
    [queryParams]="{ from: router.url }"
    >Go to company details</a
  > -->
</div>
