import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractBaseAdminService } from "@boss/secure/abstract/abstract-base-admin-service";
import { BossUpdateOrgMarketFeeActionDialogData } from "@boss/secure/dialog/boss-update-org-market-fee-action/boss-update-org-market-fee-action.dialog.data";
import { UserProfileDTO, AddressDTO } from "@shared/dto";
import { BaseResponseDTO } from "@shared/dto/BaseResponseDTO";
import { OrganizationDTO } from "@shared/dto/OrganizationDTO";
import { first } from "rxjs/operators";
import { BankAccountDTO } from "@shared/dto/BankAccountDTO";
import { OrgSearchCriteria } from "@shared/dto/OrgSearchCriteria";
import { Utils } from "@shared/constant/shared.utils";
import { BossUpdateOrgOrderCancellationFeeActionDialogData } from "@boss/secure/dialog/boss-update-org-order-cancellation-fee-action/boss-update-org-order-cancellation-fee-action.dialog.data";
import { BossUpdateOrgNegotiationDurationActionDialogData } from "@boss/secure/dialog/boss-update-org-negotiation-duration-action/boss-update-org-negotiation-duration-action.dialog.data";
import { BossUpdateOrgConfigActionDialogData } from "@boss/secure/dialog/boss-update-org-config-action/boss-update-org-config-action.dialog.data";
import { OrganizationConfigDTO } from "@shared/dto/OrganizationConfigDTO";
import { Observable } from "rxjs/internal/Observable";

@Injectable({ providedIn: "root" })
export class OrganizationService extends AbstractBaseAdminService {
  getOrganizationUserProfile(memberIdentifier: number) {
    const url = `${this.apiBaseUrl}organization/profile/${memberIdentifier}`;
    return this.http.get<BaseResponseDTO<UserProfileDTO>>(url).pipe(first());
  }

  constructor(private http: HttpClient) {
    super();
  }

  getAllOrganizations(pageIndex: number, pageSize: number) {
    const params = new HttpParams()
      .set("page", pageIndex.toString())
      .set("size", pageSize.toString());
    const url = `${this.baseUrl}organization/list`;
    return this.http
      .get<BaseResponseDTO<OrganizationDTO[]>>(url, { params: params })
      .pipe(first());
  }
  getAllApprovedOrganizations(pageIndex: number, pageSize: number) {
    const params = new HttpParams()
      .set("page", pageIndex.toString())
      .set("size", pageSize.toString());
    const url = `${this.baseUrl}organization/approvedList`;
    return this.http
      .get<BaseResponseDTO<OrganizationDTO[]>>(url, { params: params })
      .pipe(first());
  }

  getAllSuspendedOrganizations(pageIndex: number, pageSize: number) {
    const params = new HttpParams()
      .set("page", pageIndex.toString())
      .set("size", pageSize.toString());
    const url = `${this.baseUrl}organization/suspendedList`;
    return this.http
      .get<BaseResponseDTO<OrganizationDTO[]>>(url, { params: params })
      .pipe(first());
  }

  getAllPendingOrganizations(pageIndex: number, pageSize: number) {
    const params = new HttpParams()
      .set("page", pageIndex.toString())
      .set("size", pageSize.toString());
    const url = `${this.baseUrl}organization/pendingList`;
    return this.http
      .get<BaseResponseDTO<OrganizationDTO[]>>(url, { params: params })
      .pipe(first());
  }
  getAllSearchedOrganizations(
    pageIndex: number,
    pageSize: number,
    dsc: OrgSearchCriteria
  ) {
    const url = `${this.baseUrl}organization/searched/list?pageNo=${pageIndex}`;
    return this.http
      .post<BaseResponseDTO<OrganizationDTO[]>>(url, dsc)
      .pipe(first());
  }
  getOrganizationsSearchSuggestions(searchFilter: string, searchValue: string) {
    const url = `${this.baseUrl}organization/orgSearch`;
    return this.http.post<BaseResponseDTO<string[]>>(url, {
      [searchFilter]: searchValue,
    });
  }

  getAllOrganizationsCount() {
    const params = new HttpParams();
    const url = `${this.baseUrl}organization/count`;
    // return this.http.get<BaseResponseDTO<number>>(url, { params: params }).pipe(first());
    return this.http.post<BaseResponseDTO<number>>(url, null).pipe(first());
  }
  getAllOrganizationsCountWithCriteria(dsc: OrgSearchCriteria) {
    const params = Utils.toQueryParam(dsc);
    const url = `${this.baseUrl}organization/count`;
    // return this.http.get<BaseResponseDTO<number>>(url, { params: params }).pipe(first());
    return this.http.post<BaseResponseDTO<number>>(url, dsc).pipe(first());
  }
  getAllApprovedOrganizationsCount() {
    const params = new HttpParams();
    const url = `${this.baseUrl}organization/approvedCount`;
    return this.http
      .get<BaseResponseDTO<number>>(url, { params: params })
      .pipe(first());
  }
  getAllSuspendedOrganizationsCount() {
    const params = new HttpParams();
    const url = `${this.baseUrl}organization/suspendedCount`;
    return this.http
      .get<BaseResponseDTO<number>>(url, { params: params })
      .pipe(first());
  }
  getAllPendingOrganizationsCount() {
    const params = new HttpParams();
    const url = `${this.baseUrl}organization/pendingCount`;
    return this.http
      .get<BaseResponseDTO<number>>(url, { params: params })
      .pipe(first());
  }
  getAllApprovedOrganizationsCountWithCriteria(dsc: OrgSearchCriteria) {
    const params = Utils.toQueryParam(dsc);
    const url = `${this.baseUrl}organization/approvedCount`;
    return this.http
      .get<BaseResponseDTO<number>>(url, { params: params })
      .pipe(first());
  }

  detailsGet(organizationIdentifier: any) {
    const url = `${this.baseUrl}organization/${organizationIdentifier}`;
    return this.http
      .get<BaseResponseDTO<OrganizationDTO>>(url, {})
      .pipe(first());
  }

  orgPaymentdetailsGet(organizationIdentifier: any) {
    const url = `${this.baseUrl}organization/payments/${organizationIdentifier}`;
    return this.http
      .get<BaseResponseDTO<BankAccountDTO[]>>(url, {})
      .pipe(first());
  }
  updateConfig(
    bossUpdateOrgConfigActionDialogData: BossUpdateOrgConfigActionDialogData
  ) {
    const organizationConfigDTO: OrganizationConfigDTO =
      new OrganizationConfigDTO();
    organizationConfigDTO.value = bossUpdateOrgConfigActionDialogData.value;
    organizationConfigDTO.configKey = bossUpdateOrgConfigActionDialogData.key;
    organizationConfigDTO.dataType =
      bossUpdateOrgConfigActionDialogData.dataType;
    organizationConfigDTO.organizationIdentifier =
      bossUpdateOrgConfigActionDialogData.organizationIdentifier;
    organizationConfigDTO.remarks = bossUpdateOrgConfigActionDialogData.txText;
    const url = `${this.baseUrl}organization/${bossUpdateOrgConfigActionDialogData.organizationIdentifier}/${bossUpdateOrgConfigActionDialogData.key}/updateOrganizationConfig`;
    return this.http
      .post<BaseResponseDTO<OrganizationConfigDTO>>(url, organizationConfigDTO)
      .pipe(first());
  }
  updateMarketPlaceFee(
    bossUpdateOrgMarketFeeActionDialogData: BossUpdateOrgMarketFeeActionDialogData
  ) {
    const organizationDTO: OrganizationDTO = new OrganizationDTO();
    organizationDTO.marketPlaceCommission =
      bossUpdateOrgMarketFeeActionDialogData.boxxportFee;
    organizationDTO["remarks"] = bossUpdateOrgMarketFeeActionDialogData.txText;
    const url = `${this.baseUrl}organization/${bossUpdateOrgMarketFeeActionDialogData.organizationIdentifier}/updateMPCommission`;
    return this.http
      .post<BaseResponseDTO<OrganizationDTO>>(url, organizationDTO)
      .pipe(first());
  }
  updateNegotiationDuration(
    updateOrgNegotiationDurationActionDialogData: BossUpdateOrgNegotiationDurationActionDialogData
  ) {
    const organizationDTO: OrganizationDTO = new OrganizationDTO();
    organizationDTO.negotiationDuration =
      updateOrgNegotiationDurationActionDialogData.negotiationDuration;
    organizationDTO["remarks"] =
      updateOrgNegotiationDurationActionDialogData.txText;
    const url = `${this.baseUrl}organization/${updateOrgNegotiationDurationActionDialogData.organizationIdentifier}/updateNegotiationDuration`;
    return this.http
      .post<BaseResponseDTO<OrganizationDTO>>(url, organizationDTO)
      .pipe(first());
  }
  updateOrderCancellationFee(
    bossUpdateOrgOrderCancellationFeeActionDialogData: BossUpdateOrgOrderCancellationFeeActionDialogData
  ) {
    const organizationDTO: OrganizationDTO = new OrganizationDTO();
    organizationDTO.orderCancellationFee =
      bossUpdateOrgOrderCancellationFeeActionDialogData.orderCancellationFee;
    organizationDTO["remarks"] =
      bossUpdateOrgOrderCancellationFeeActionDialogData.txText;
    const url = `${this.baseUrl}organization/${bossUpdateOrgOrderCancellationFeeActionDialogData.organizationIdentifier}/updateOrderCancellationFee`;
    return this.http
      .post<BaseResponseDTO<OrganizationDTO>>(url, organizationDTO)
      .pipe(first());
  }
  getOrganizationList() {
    return this.http
      .get<BaseResponseDTO<OrganizationDTO[]>>(
        this.apiBaseUrl + "organization/all"
      )
      .pipe(first());
  }

  getApprovedOrganizationList() {
    return this.http
      .get<BaseResponseDTO<OrganizationDTO[]>>(
        this.apiBaseUrl + "organization/allApproved"
      )
      .pipe(first());
  }

  getBuyApprovedOrganizationList() {
    return this.http
      .get<BaseResponseDTO<OrganizationDTO[]>>(
        this.apiBaseUrl + "organization/allApprovedBuy"
      )
      .pipe(first());
  }

  getSellApprovedOrganizationList() {
    return this.http
      .get<BaseResponseDTO<OrganizationDTO[]>>(
        this.apiBaseUrl + "organization/allApprovedSell"
      )
      .pipe(first());
  }

  getOrganizationDetails(organizationIdentifier: any) {
    const url = `${this.apiBaseUrl}organization/${organizationIdentifier}`;
    return this.http
      .get<BaseResponseDTO<OrganizationDTO>>(url, {})
      .pipe(first());
  }
  getOrganizationDetailsForIdentifier(organizationIdentifier: any) {
    const url = `${this.baseUrl}organization/detail/${organizationIdentifier}`;
    return this.http
      .get<BaseResponseDTO<OrganizationDTO>>(url, {})
      .pipe(first());
  }

  getCurrentUserOrgnization() {
    const url = `${this.apiBaseUrl}organization/user`;
    return this.http
      .get<BaseResponseDTO<OrganizationDTO>>(url, {})
      .pipe(first());
  }
  getOrganizationUsers() {
    const url = `${this.apiBaseUrl}organization/users`;
    return this.http.get<BaseResponseDTO<UserProfileDTO[]>>(url).pipe(first());
  }
  getOrganizationUsersCount() {
    const url = `${this.apiBaseUrl}organization/users/count`;
    return this.http.get<BaseResponseDTO<any>>(url).pipe(first());
  }
  saveAndUpdateOrganizationDetails(orgdentifier: number, object: any) {
    let apiUrl = `${this.apiBaseUrl}organization/companyDetails`;
    if (orgdentifier) {
      apiUrl = `${apiUrl}/${orgdentifier}`;
    }
    return this.http
      .post<BaseResponseDTO<OrganizationDTO>>(`${apiUrl}`, object)
      .pipe(first());
  }

  submitOrganization(organizationIdentifier: string): any {
    const url = `${this.apiBaseUrl}organization/submit/${organizationIdentifier}`;
    return this.http.put<BaseResponseDTO<any>>(url, {}).pipe(first());
  }

  markDefaultOrganization(organizationIdentifier: string): any {
    const headers = new HttpHeaders({
      "api-message-code": "set.default.organization",
    });
    const url = `${this.apiBaseUrl}organization/markDefault/${organizationIdentifier}`;
    return this.http
      .put<BaseResponseDTO<any>>(url, {}, { headers: headers })
      .pipe(first());
  }

  removeOrganization(organizationIdentifier: string): any {
    const url = `${this.apiBaseUrl}organization/remove/${organizationIdentifier}`;
    return this.http.put<BaseResponseDTO<any>>(url, {}).pipe(first());
  }

  billingAddress(organizationIdentifier?: string) {
    let url = `${this.apiBaseUrl}billing/address/`;
    if (organizationIdentifier) {
      url += organizationIdentifier;
    }
    return this.http.get<BaseResponseDTO<any>>(url).pipe(first());
  }
  saveBillingAddress(address: AddressDTO, organizationIdentifier: string) {
    let url = `${this.apiBaseUrl}organization/updateBillingAddress/${organizationIdentifier}`;
    if (address.identifier) {
      url += "/" + address.identifier;
    }
    return this.http.put<BaseResponseDTO<any>>(url, address).pipe(first());
  }
  canNegotiate() {
    let url = `${this.apiBaseUrl}organization/canNegotiate`;
    return this.http.get<BaseResponseDTO<Boolean>>(url, {}).pipe(first());
  }
  canNegotiateByOrg(sellerOrg: string) {
    let url = `${this.apiBaseUrl}organization/canNegotiate/${sellerOrg}`;
    return this.http.get<BaseResponseDTO<Boolean>>(url, {}).pipe(first());
  }

  adminUpdateOrganizationDetails(
    orgdentifier: string,
    organizationDTO: OrganizationDTO
  ) {
    if (organizationDTO.companyType != "Other") {
      organizationDTO.otherCompanyType = undefined;
    }
    const url = `${this.baseUrl}organization/${orgdentifier}/updateOrganizationDetails`;
    return this.http
      .post<BaseResponseDTO<OrganizationDTO>>(url, organizationDTO)
      .pipe(first());
  }
  adminUpdateOrganizationPaymentDetails(
    orgdentifier: string,
    bankAccountDTO: Map<string, any>
  ) {
    const url = `${this.baseUrl}organization/${orgdentifier}/updateOrganizationPaymentDetails`;
    const convMap = {};
    bankAccountDTO.forEach((val: string, key: string) => {
      convMap[key] = val;
    });
    return this.http
      .post<BaseResponseDTO<OrganizationDTO>>(url, convMap)
      .pipe(first());
  }
  // adminUpdateSprinquePaymentdetails(organizationIdentifier: any, payload: any) {
  //   const url = `${this.baseUrl}organization/${organizationIdentifier}/updateOrganizationSprinqueDetails`;
  //   return this.http
  //     .post<BaseResponseDTO<OrganizationDTO>>(url, payload)
  //     .pipe(first());
  // }
  adminUpdateOrganizationWalletDetails(
    organizationIdentifier: any,
    payload: any
  ): Observable<BaseResponseDTO<OrganizationDTO>> {
    const url = `${this.baseUrl}organization/${organizationIdentifier}/updateOrganizationWalletDetails`;
    return this.http
      .post<BaseResponseDTO<OrganizationDTO>>(url, payload)
      .pipe(first());
  }
  uploadOrgImage(files: File[], orgIdentifier: string) {
    const url = `${this.apiBaseUrl}media/uploadimage`;
    const formData: FormData = new FormData();
    console.log(files);
    files.forEach((file) => {
      formData.append("files", file);
      console.log(file);
    });

    formData.append("modelIdentifier", orgIdentifier);
    formData.append(
      "documentUploadPathType",
      "IMAGE_UPLOAD_WITH_USER_ORGANIZATION"
    );
    return this.http.post<BaseResponseDTO<any>>(url, formData);
  }
  getAllOrganizationUsers() {
    // const params = new HttpParams();
    // const url = `${this.apiBaseUrl}organization/getAllUserOrg`;
    return this.http
      .get<BaseResponseDTO<OrganizationDTO[]>>(
        this.apiBaseUrl + "organization/getAllUserOrg"
      )
      .pipe(first());
  }

  getAllUserForOrg() {
    return this.http
      .get<BaseResponseDTO<any>>(
        this.apiBaseUrl + "organization/getAllUserForOrg"
      )
      .pipe(first());
  }
}
// getOrganizationList() {
//   return this.http
//     .get<BaseResponseDTO<OrganizationDTO[]>>(
//       this.apiBaseUrl + "organization/all"
//     )
//     .pipe(first());
// }
