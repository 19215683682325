import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { styles } from "ng-block-ui/components/block-ui-content/block-ui-content.component.style";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "control-messages",
  template: `<span
    class="hasErrorSpan"
    *ngIf="errorMessage !== null"
    [innerHtml]="errorMessage"
    >{{ errorMessage }}</span
  >`,
  styles: [
    `
      .hasErrorSpan {
        font-size: 14px;
        /* width: 300px; */
        line-height: normal;
      }
    `,
  ],
})
export class ControlMessagesComponent {
  @Input() control: FormControl;

  constructor(private translateService: TranslateService) {}

  get errorMessage() {
    if (this.control) {
      for (const propertyName in this.control.errors) {
        if (
          this.control.errors.hasOwnProperty(propertyName) &&
          this.control.touched
        ) {
          const parentFormGroup = this.control["_parent"];
          const controls = parentFormGroup.controls;
          const formName = parentFormGroup["formName"] || "unknown";
          for (const key in controls) {
            if (controls.hasOwnProperty(key)) {
              if (controls[key] === this.control) {
                return this.getValidatorErrorMessage(
                  formName,
                  key,
                  propertyName,
                  this.control.errors[propertyName]
                );
              }
            }
          }
          return this.getValidatorErrorMessage(
            formName,
            undefined,
            propertyName,
            this.control.errors[propertyName]
          );
        }
      }
    }
    return null;
  }

  private getValidatorErrorMessage(
    formName: string,
    proertyKey: string,
    validatorName: string,
    validatorValue?: any
  ) {
    let code = formName;
    if (proertyKey) {
      code += "." + proertyKey;
    }
    if (validatorName) {
      code += "." + validatorName;
    }
    return this.translateService.instant(code);
  }
}
