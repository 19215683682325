import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractBaseService } from "@shared/abstracts/abstract-base-service";
import { Utils } from "@shared/constant/shared.utils";
import { ContainerSearchCriteria, OrderDTO } from "@shared/dto";
import { BaseResponseDTO } from "@shared/dto/BaseResponseDTO";
import { CreateOrderRequest } from "@shared/dto/CreateOrderRequest";
import { CreateOrderResponse } from "@shared/dto/CreateOrderResponse";
import { PlaceOrderRequest } from "@shared/dto/PlaceOrderRequest";
import { first } from "rxjs/operators";
import { OrderItemDTO } from "@shared/dto/OrderItemDTO";
import { CancelOrderModel } from "@shared/dto/cancelOrderModel";
import { ReDeliveryLocationPriceDTO } from "@shared/dto/ReDeliveryLocationPriceDTO";
import { ContainerRadarSearchCriteria } from "@shared/dto/containerRadarSearchCriteria";

@Injectable({ providedIn: "root" })
export class OrderService extends AbstractBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  private baseUrl() {
    return `${this.apiBaseUrl}orders/`;
  }

  getBuyerOrderCountGet() {
    return this.http
      .get<BaseResponseDTO<number>>(`${this.baseUrl()}count`)
      .pipe(first());
  }

  deleteOrderDelete(id: string) {
    return this.http
      .delete<BaseResponseDTO<any>>(`${this.baseUrl()}${id}`)
      .pipe(first());
  }

  getBuyerOrdersGet(page: number = 10, size: number = 0) {
    const params: HttpParams = new HttpParams()
      .set("page", page.toString())
      .set("size", size.toString());
    return this.http
      .get<BaseResponseDTO<any[]>>(`${this.baseUrl()}`, {
        params,
      })
      .pipe(first());
  }

  orderStatus(orderIdentifiers) {
    return this.http
      .get<BaseResponseDTO<any>>(`${this.baseUrl()}status/${orderIdentifiers}`)
      .pipe(first());
  }
  orderItemStatus(orderItemIdentifiers) {
    return this.http
      .get<BaseResponseDTO<any>>(
        `${this.baseUrl()}item/status/${orderItemIdentifiers}`
      )
      .pipe(first());
  }

  getBuyerOrdersByOrderIdGet(orderId: number) {
    return this.http
      .get<BaseResponseDTO<OrderDTO[]>>(`${this.baseUrl()}link/${orderId}`, {})
      .pipe(first());
  }

  placeOrder(orderModel: PlaceOrderRequest) {
    return this.http
      .post<BaseResponseDTO<any>>(
        `${this.baseUrl()}place/${orderModel.orderIdentifier}`,
        orderModel
      )
      .pipe(first());
  }

  createOrderPost(orderModel: CreateOrderRequest) {
    return this.http
      .post<BaseResponseDTO<CreateOrderResponse>>(
        `${this.baseUrl()}create`,
        orderModel
      )
      .pipe(first());
  }

  updateOrderPut(object: any) {
    return this.http
      .put<BaseResponseDTO<any>>(`${this.baseUrl()}`, object)
      .pipe(first());
  }

  getNewAveragePrice(
    containerRadarSearchCriteria: ContainerRadarSearchCriteria
  ) {
    // const params = Utils.toQueryParam(containerRadarSearchCriteria);
    return this.http
      .post<BaseResponseDTO<number>>(
        `${this.apiBaseUrl}seller/product/getContainerAveragePriceList`,
        containerRadarSearchCriteria
      )
      .pipe(first());
  }

  calculateAverageMarketPrice(csc: ContainerSearchCriteria, onSuccess) {
    csc.checklistStatus.push("APPROVED");
    this.getAverageMarketPrice(csc).subscribe(onSuccess);
  }
  getAverageMarketPrice(containerSearchCriteria: ContainerSearchCriteria) {
    const params = Utils.toQueryParam(containerSearchCriteria);
    return this.http
      .get<BaseResponseDTO<number>>(`${this.baseUrl()}marketPrice`, { params })
      .pipe(first());
  }

  getBuyerOrderItemCountGet(status: string, object: object) {
    const params = Utils.toQueryParam(object);
    if (params) {
      return this.http
        .get<BaseResponseDTO<number>>(
          `${this.baseUrl()}item/list/${status}/count`,
          {
            params,
          }
        )
        .pipe(first());
    } else {
      return this.http
        .get<BaseResponseDTO<number>>(
          `${this.baseUrl()}item/list/${status}/count`
        )
        .pipe(first());
    }
  }

  getBuyerOrderItemsGet(status: string, object: object) {
    const params = Utils.toQueryParam(object);
    if (params) {
      return this.http
        .get<BaseResponseDTO<OrderItemDTO[]>>(
          `${this.baseUrl()}item/list/${status}`,
          {
            params,
          }
        )
        .pipe(first());
    } else {
      return this.http
        .get<BaseResponseDTO<OrderItemDTO[]>>(
          `${this.baseUrl()}item/list/${status}`
        )
        .pipe(first());
    }
  }

  getBuyerOrderItemDetailsGet(orderItemIdentifier: number) {
    return this.http
      .get<BaseResponseDTO<OrderItemDTO>>(
        `${this.baseUrl()}item/${orderItemIdentifier}`
      )
      .pipe(first());
  }

  uploadOrderItemDocument(
    orderItemIdentifier: number,
    object: any,
    includeHeader = true
  ) {
    let headers;
    if (includeHeader) {
      headers = new HttpHeaders({
        "api-message-code": "upload.order.document",
      });
    }
    const apiUrl = `${this.baseUrl()}item/uploadDocument/${orderItemIdentifier}`;
    return this.http
      .post<BaseResponseDTO<OrderItemDTO>>(`${apiUrl}`, object, {
        headers: headers,
      })
      .pipe(first());
  }

  removeOrderItemDocument(
    orderItemIdentifier: number,
    documentIdentifier: number
  ) {
    const headers = new HttpHeaders({
      "api-message-code": "delete.order.document",
    });
    const apiUrl = `${this.baseUrl()}item/removeDocument/${orderItemIdentifier}/${documentIdentifier}`;
    return this.http
      .post<BaseResponseDTO<OrderItemDTO>>(
        `${apiUrl}`,
        {},
        { headers: headers }
      )
      .pipe(first());
  }
  markOrderPaymentFailed(orderlinkIdentifier: any) {
    const apiUrl = `${this.baseUrl()}markFailed/${orderlinkIdentifier}`;
    return this.http.post<BaseResponseDTO<void>>(`${apiUrl}`, {}).pipe(first());
  }

  cancelOrderItem(cancelOrderModel: CancelOrderModel) {
    const apiUrl = `${this.baseUrl()}item/cancel/${
      cancelOrderModel.orderItemId
    }`;
    return this.http
      .post<BaseResponseDTO<OrderItemDTO>>(`${apiUrl}`, cancelOrderModel)
      .pipe(first());
  }
  getOrderCancelReasons() {
    return this.http
      .get<BaseResponseDTO<string[]>>(`${this.baseUrl()}cancel/reasonKeys`)
      .pipe(first());
  }
  convertToBuyBack(redeliveries: ReDeliveryLocationPriceDTO[]) {
    return this.http
      .post<BaseResponseDTO<any>>(
        `${this.baseUrl()}convert/addBuyback`,
        redeliveries
      )
      .pipe(first());
  }
}
