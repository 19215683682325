import { ProductListingActionStatus } from "@marketplace/marketplace-product-core/enums/ProductListingActionStatus.enums";
import { BaseDTO } from "@shared/dto/BaseDTO";
import { BuyerContainerDto } from "@shared/dto/BuyerContainerDto";
import { ContainerImageDto } from "@shared/dto/ContainerImageDto";
import { NegotiationDTO } from "@shared/dto/NegotiationDTO";
import { ReDeliveryLocationPriceDTO } from "@shared/dto/ReDeliveryLocationPriceDTO";

export class ProductListingDTO extends BaseDTO {
  cartIdentifier;

  followStatus: ProductListingActionStatus;
  isSubscribe: Boolean;
  units: number;

  isAddedForCompare = false;

  sellerOrgIdentifier: string;
  subtitle: string;
  name: string;
  quantityAvailable: number;
  onRequest: Boolean;

  isAddedForCart = false;

  viewCount = 0;
  rank: number;

  container: BuyerContainerDto;
  sellAsLot: Boolean;

  negotiable: boolean = false;

  negotiationDTO: NegotiationDTO;

  buyback: boolean = false;
  buybackLocations: ReDeliveryLocationPriceDTO[] = [];

  sellerMaskedName: string;
  sellerAvgRating: number;

  status: string;
  shortName: string;

  description: string;

  cscValidTill: Date;

  validFrom: Date;

  validTo: Date;

  submittedDate: Date;

  approvedDate: Date;

  type: string;

  typeCode: string;

  grade: string;

  gradeCode: string;

  innerRALCode: string;

  innerRALColorCode: string;

  outerRALCode: string;

  outerRALColorCode: string;

  fulfilled: Boolean;

  specialEquipmentCodeList: string;

  specialEquipmentDescriptionList: string;

  yearOfManufacture: string;

  containerPrefix: string;

  cscCertificateAvailable: Boolean;

  outOfStock: boolean;

  isApplyDiscount: Boolean;

  inventoryName: string;

  inventoryCode: string;

  inventoryFormattedAddress: string;

  inventoryAddressLine1: string;

  inventoryAddressLine2: string;

  inventoryCity: string;

  inventoryCountry: string;

  inventoryCountryShortName: string;

  inventoryState: string;

  inventoryZipCode: string;

  inventoryRegion: string;

  lat?: number;

  lang?: number;

  vatPercentage: number;

  sellerPrice: number;

  finalPrice: number;

  finalPriceWithoutVat: number;

  discountedSellerPrice: number;

  discountedFinalPrice: number;

  discountedFinalPriceWithoutVat: number;

  youSave: number;

  youSavePercent: number;

  thumbnailUrl: string;

  productImageList: ContainerImageDto[];

  pageHitCount: number;
  soldCount: number;
  soldCountPeriod: string;
  isExport: boolean;
  exportCountry: string;
  isActive: Boolean;
  isAccessible: Boolean;

  availableFrom: Date;

  availableTo: Date;

  priceFluctuation: Boolean;

  boxxportChoice: Boolean;
  orgBoxxportChoice: boolean;
  currency_flag: string;

  redeliveryLocations: ReDeliveryLocationPriceDTO[] = [];
}
// import { ProductListingActionStatus } from "@marketplace/marketplace-product-core/enums/ProductListingActionStatus.enums";
// import { BaseDTO } from "@shared/dto/BaseDTO";
// import { BuyerContainerDto } from "@shared/dto/BuyerContainerDto";
// import { NegotiationDTO } from "@shared/dto/NegotiationDTO";
// import { ReDeliveryLocationPriceDTO } from "@shared/dto/ReDeliveryLocationPriceDTO";

// export class ProductListingDTO extends BaseDTO {
//   cartIdentifier;

//   followStatus: ProductListingActionStatus;

//   isAddedForCompare = false;

//   isAddedForCart = false;

//   viewCount = 0;

//   container: BuyerContainerDto;

//   negotiable: boolean = false;

//   negotiationDTO: NegotiationDTO;

//   buyback: boolean = false;
//   buybackLocations: ReDeliveryLocationPriceDTO[] = [];
//   //   currencyBlock: boolean;
// }
