import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { MapPlaceComponent } from "@marketplace/marketplace-product-core/marketplace-map-place/map.component";
import { MarketplaceRatingCoreModule } from "@marketplace/marketplace-rating-core/marketplace-rating-core.module";
import { NavigationService } from "@marketplace/service/navigation.service";
import { ZendeskService } from "@marketplace/service/zendesk.service";
import { VideoComponent } from "@marketplace/ui/video/video.component";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { MapCoreModule } from "@shared/map/map-core.module";
import { SharedModule } from "@shared/shared.module";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverConfig, PopoverModule } from "ngx-bootstrap/popover";
import { ToastrModule } from "ngx-toastr";
import { AssociateCompanyItemComponent } from "./associate-company/associate-company-item/associate-company-item.component";
import { AssociateCompanyComponent } from "./associate-company/associate-company.component";
import { BackButtonComponent } from "./back-button.component";
import { BillingAddressComponent } from "./billing-address/billing-address.component";
import { CancelOrderModalComponent } from "./cancel-order-modal/cancel-order-modal.component";
import { CartPaymentModalComponent } from "./cart-payment-modal/cart-payment-modal.component";
import { CustomDatepickerComponent } from "./custom-datepicker/custom-datepicker.component";
import { ErrorModalComponent } from "./error-modal/error-modal.component";
import { MarketplaceBack2TopComponent } from "./marketplace-back2-top/marketplace-back2-top.component";
import { MarketplaceBankAccountModelComponent } from "./marketplace-bank-account-model/marketplace-bank-account-model.component";
import { MarketplaceBestContainerPricesComponent } from "./marketplace-best-container-prices/marketplace-best-container-prices.component";
import { MarketplaceBuyBackSearchComponent } from "./marketplace-buyback-search/marketplace-buyback-search.component";
import { MarketplaceCarouselComponent } from "./marketplace-carousel/marketplace-carousel.component";
import { MarketplaceCompanyPeopleProfilesComponent } from "./marketplace-company-people-profiles/marketplace-company-people-profiles.component";
import { MarketplaceConfirmDialogComponent } from "./marketplace-confirm-dialog/marketplace-confirm-dialog.component";
import { MarketplaceEfficientAccessibleComponent } from "./marketplace-efficient-accessible/marketplace-efficient-accessible.component";
import { MarketplaceFindBestContainerPricesComponent } from "./marketplace-find-best-container-prices/marketplace-find-best-container-prices.component";
import { MarketplaceGetStartedActionComponent } from "./marketplace-get-started-action/marketplace-get-started-action.component";
import { MarketplaceGetStartedComponent } from "./marketplace-get-started/marketplace-get-started.component";
import { MarketplaceImgPreviewComponent } from "./marketplace-img-preview/marketplace-img-preview.component";
import { MarketplaceLiveInventoryComponent } from "./marketplace-live-inventory/marketplace-live-inventory.component";
import { MarketplaceLoadMoreComponent } from "./marketplace-load-more/marketplace-load-more.component";
import { MarketplaceNoDataComponent } from "./marketplace-no-data/marketplace-no-data.component";
import { MarketplaceResizeComponent } from "./marketplace-resize/marketplace-resize.component";
import { MarketplaceSeeMoreComponent } from "./marketplace-see-more/marketplace-see-more.component";
import { MarketplaceServiceProductInfoComponent } from "./marketplace-service-product-info/marketplace-service-product-info.component";
import { MarketplaceServiceProductComponent } from "./marketplace-service-product/marketplace-service-product.component";
import { MarketplaceTAndCComponent } from "./marketplace-tand-c/marketplace-tand-c.component";
import { MarketplaceTransparantProcessComponent } from "./marketplace-transparant-process/marketplace-transparant-process.component";
import { MarketplaceTrustedByComponent } from "./marketplace-trusted-by/marketplace-trusted-by.component";
import { MarketplaceWelcomeComponent } from "./marketplace-welcome/marketplace-welcome.component";
import { NgSlimScrollModule } from "./ngx-slimscroll";
import { ProvideRatingComponent } from "./provide-rating/provide-rating.component";
import { SubscribeExternalAppComponent } from "./subscribe-external-app/subscribe-external-app.component";
import { UpdateDefaultCompanyHeaderComponent } from "./update-default-company-header/update-default-company-header.component";
import { UpdatePriceModalComponent } from "./update-price-modal/update-price-modal.component";
import { VideoPlayerModalComponent } from "./video-player-modal/video-player-modal.component";
import { MarketplaceStocksComponent } from "./marketplace-stocks/marketplace-stocks.component";
import { DashboardPriceIndexComponent } from "./dashboard-price-index/dashboard-price-index.component";
import { SharedChartCoreModule } from "@shared/shared-chart-core/shared-chart-core.module";
import { MarketplaceShareComponent } from "./marketplace-share/marketplace-share.component";
import { MarketplaceSellContainerComponent } from "./marketplace-sell-container/marketplace-sell-container.component";
import { MarketplaceBuybackComponent } from "./marketplace-buyback/marketplace-buyback.component";
import { MarketplaceRadarComponent } from "./marketplace-radar/marketplace-radar.component";
import { PlacesAutocompleteComponent } from "./map-utils/places-autocomplete/places-autocomplete.component";
import { MakertplaceProductAddToCartComponent } from "@marketplace/marketplace-product-core/action/marketplace-product-add-to-cart/marketplace-product-add-to-cart.component";
import { MakertplaceProductBuyNowComponent } from "@marketplace/marketplace-product-core/action/marketplace-product-buy-now/marketplace-product-buy-now.component";
import { MarketplacePriceInfoComponent } from "@marketplace/marketplace-product-core/marketplace-price-info/marketplace-price-info.component";
import { MarketplacePriceComponent } from "@marketplace/marketplace-product-core/marketplace-price/marketplace-price.component";
import { MarketplaceProductTaxInfoComponent } from "@marketplace/marketplace-product-core/marketplace-product-tax-info/marketplace-product-tax-info.component";
import { MarketplaceSellAsALotComponent } from "@marketplace/marketplace-product-core/marketplace-sell-as-alot/marketplace-sell-as-alot.component";
import { MarketplaceSellerInfoComponent } from "@marketplace/marketplace-product-core/marketplace-seller-info/marketplace-seller-info.component";
import { ContainerCarouselComponentHome } from "./container-carousel-home/container-carousel-home.component";
import { ToolsComponent } from "./tools/tools.component";
import { UtimateToolComponent } from "./utimate-tool/utimate-tool.component";
import { MarketplaceDropdownCheckboxComponent } from "./marketplace-dropdown-checkbox/marketplace-dropdown-checkbox.component";
import { BoxxportMissionComponent } from "./boxxport-mission/boxxport-mission.component";
import { FaqsComponent } from "./faqs/faqs.component";
import { DynamicBannerComponent } from "./dynamic-banner/dynamic-banner.component";
import { DynamicBannerPlugDirective } from "./dynamic-banner/dynamic-banner-plug.directive";
import { BreakbulkComponent } from "./dynamic-banner/dynamic-banner-items/breakbulk/breakbulk.component";
import { BuyNowPayLaterComponent } from "./dynamic-banner/dynamic-banner-items/buy-now-pay-later/buy-now-pay-later.component";
import { ContainerTradingWithoutLimitsComponent } from "./container-trading-without-limits/container-trading-without-limits.component";
import { BuySellBoxxportComponent } from "./buy-sell-boxxport/buy-sell-boxxport.component";
import { MatCardModule } from "@angular/material/card";
import { BlogComponent } from "./blog/blog.component";
import { MatTooltip, MatTooltipModule } from "@angular/material/tooltip";

export function getPopoverConfig(): PopoverConfig {
  return Object.assign(new PopoverConfig(), {
    outsideClick: true,
    placement: "right",
  });
}

@NgModule({
  imports: [
    SharedModule,
    NgSlimScrollModule,
    ToastrModule.forRoot({
      closeButton: true,
      // disableTimeOut: true,
    }),
    PopoverModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    MapCoreModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ScrollToModule.forRoot(),
    MarketplaceRatingCoreModule,
    SharedChartCoreModule,
    MatCardModule,
    CarouselModule,
    MatTooltipModule,
    // MarketplaceProductCoreModule,
  ],
  providers: [
    { provide: PopoverConfig, useFactory: getPopoverConfig },
    NavigationService,
    ZendeskService,
  ],
  declarations: [
    BackButtonComponent,
    MarketplaceWelcomeComponent,
    CustomDatepickerComponent,
    MarketplaceBankAccountModelComponent,
    MarketplaceGetStartedComponent,
    MarketplaceTrustedByComponent,
    MarketplaceEfficientAccessibleComponent,
    MarketplaceTransparantProcessComponent,
    MarketplaceBestContainerPricesComponent,
    MarketplaceLiveInventoryComponent,
    MarketplaceFindBestContainerPricesComponent,
    MarketplaceImgPreviewComponent,
    MarketplaceResizeComponent,
    MarketplaceLoadMoreComponent,
    VideoComponent,
    MarketplaceSeeMoreComponent,
    MarketplaceCarouselComponent,
    AssociateCompanyComponent,
    MarketplaceConfirmDialogComponent,
    BillingAddressComponent,
    MapPlaceComponent,
    MarketplaceTAndCComponent,
    AssociateCompanyItemComponent,
    MarketplaceNoDataComponent,
    MarketplaceServiceProductComponent,
    MarketplaceServiceProductInfoComponent,
    VideoPlayerModalComponent,
    MarketplaceCompanyPeopleProfilesComponent,
    MarketplaceBack2TopComponent,
    MarketplaceGetStartedActionComponent,
    ProvideRatingComponent,
    ErrorModalComponent,
    CancelOrderModalComponent,
    SubscribeExternalAppComponent,
    UpdatePriceModalComponent,
    CartPaymentModalComponent,
    UpdateDefaultCompanyHeaderComponent,
    MarketplaceBuyBackSearchComponent,
    MarketplaceStocksComponent,
    DashboardPriceIndexComponent,
    MarketplaceShareComponent,
    MarketplaceSellContainerComponent,
    MarketplaceBuybackComponent,
    MarketplaceRadarComponent,
    PlacesAutocompleteComponent,
    UtimateToolComponent,
    ToolsComponent,
    ContainerCarouselComponentHome,
    MakertplaceProductBuyNowComponent,
    MakertplaceProductAddToCartComponent,
    MarketplacePriceComponent,
    MarketplacePriceInfoComponent,
    MarketplaceSellerInfoComponent,
    MarketplaceProductTaxInfoComponent,
    MarketplaceSellAsALotComponent,
    MarketplaceDropdownCheckboxComponent,
    BoxxportMissionComponent,
    FaqsComponent,
    DynamicBannerComponent,
    DynamicBannerPlugDirective,
    BreakbulkComponent,
    BuyNowPayLaterComponent,
    ContainerTradingWithoutLimitsComponent,
    BuySellBoxxportComponent,
    BlogComponent,
  ],
  exports: [
    FormsModule,
    BackButtonComponent,
    MarketplaceWelcomeComponent,
    MarketplaceBankAccountModelComponent,
    ModalModule,
    CustomDatepickerComponent,
    PopoverModule,
    SharedModule,
    MarketplaceImgPreviewComponent,
    MarketplaceGetStartedComponent,
    MarketplaceLiveInventoryComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MarketplaceResizeComponent,
    MarketplaceLoadMoreComponent,
    VideoComponent,
    MarketplaceConfirmDialogComponent,
    MarketplaceSeeMoreComponent,
    MarketplaceCarouselComponent,
    AssociateCompanyComponent,
    BillingAddressComponent,
    ErrorModalComponent,
    MapPlaceComponent,
    MarketplaceTAndCComponent,
    MarketplaceNoDataComponent,
    MarketplaceTrustedByComponent,
    MarketplaceServiceProductComponent,
    MarketplaceServiceProductInfoComponent,
    VideoPlayerModalComponent,
    MarketplaceCompanyPeopleProfilesComponent,
    MarketplaceGetStartedActionComponent,
    ProvideRatingComponent,
    CancelOrderModalComponent,
    SubscribeExternalAppComponent,
    UpdatePriceModalComponent,
    CartPaymentModalComponent,
    UpdateDefaultCompanyHeaderComponent,
    MarketplaceFindBestContainerPricesComponent,
    MarketplaceBuyBackSearchComponent,
    MarketplaceStocksComponent,
    DashboardPriceIndexComponent,
    MarketplaceShareComponent,
    MakertplaceProductBuyNowComponent,
    MakertplaceProductAddToCartComponent,
    MarketplacePriceComponent,
    MarketplacePriceInfoComponent,
    MarketplaceSellerInfoComponent,
    MarketplaceProductTaxInfoComponent,
    MarketplaceSellAsALotComponent,
    MarketplaceSellContainerComponent,
    MarketplaceRadarComponent,
    FaqsComponent,
  ],
})
export class MarketplaceCoreModule {}
